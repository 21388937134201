import React from 'react'
import {TextField} from 'wix-ui-tpa/TextField'
import {
  getCurrencySymbol,
  isCurrencySuffixed,
  normalizePrice,
  getFormattedPrice,
  minimumDonation,
  MAX_PRICE,
} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {DONATION_ERROR} from '../../../../../types'
import {classes} from './donation-amount.st.css'
import {DonationAmountProps} from './interfaces'

export const DonationAmount = ({ticket, onChange, onBlur, value, error}: DonationAmountProps) => {
  const {t} = useTranslation()

  const currency = ticket.price.currency
  const suffixedCurrency = isCurrencySuffixed(currency)
  const symbol = getCurrencySymbol(currency)
  const minimum = minimumDonation(ticket)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 0) {
      onChange(undefined)
    } else {
      const normalizedValue = normalizePrice(event.target.value, value?.toString(), {max: MAX_PRICE})
      onChange(normalizedValue !== undefined ? Number(normalizedValue) : undefined)
    }
  }

  const getError = () => {
    const ERRORS = {
      [DONATION_ERROR.EMPTY_DONATION]: () => t('tickets.donationError.empty'),
      [DONATION_ERROR.MINIMUM_NOT_REACHED]: () =>
        t('tickets.donationError.minimum', {price: getFormattedPrice(minimum, currency)}),
    }
    return ERRORS[error]()
  }

  return (
    <TextField
      value={value?.toString() ?? ''}
      className={classes.root}
      label={t('tickets.donationInputLabel')}
      placeholder={minimum ? t('tickets.donationMinimumPlaceholder', {minimum}) : '0'}
      error={Boolean(error)}
      errorMessage={error ? getError() : undefined}
      onChange={handleChange}
      onBlur={onBlur}
      prefix={suffixedCurrency ? undefined : symbol}
      suffix={suffixedCurrency ? symbol : undefined}
    />
  )
}

import {getEventTicketLimitPerOrder, saleScheduled} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {isChooseSeatMode} from '../../../../../selectors/navigation'
import {getSelectedTicketQuantity, getSelectedTicketsQuantity} from '../../../../../selectors/selected-tickets'
import {getTicketById} from '../../../../../selectors/tickets'
import {isSeatingCollapsedAreaTicketsEnabled} from '../../../../../../../../commons/selectors/experiments'
import {TicketAction as Presentation} from './ticket-action'
import {TicketActionOwnProps, TicketActionRuntimeProps} from './interfaces'

const mapRuntime = (
  {state, actions: {addPlaceToBasket, setSeatingError}}: DetailsPageAppProps,
  {place}: TicketActionOwnProps,
): TicketActionRuntimeProps => {
  const ticket = getTicketById(state.tickets, place?.ticketId)

  return {
    selectedTicketQuantity: getSelectedTicketQuantity(state, place?.ticketId, place?.id),
    selectedTicketsQuantity: getSelectedTicketsQuantity(state),
    chooseSeatMode: isChooseSeatMode(state),
    addPlaceToBasket,
    setSeatingError,
    scheduled: ticket ? saleScheduled(ticket) : undefined,
    eventTicketLimitPerOrder: getEventTicketLimitPerOrder(state.event),
    description: ticket.description,
    collapsedAreaTicket: isSeatingCollapsedAreaTicketsEnabled(state),
  }
}

export const TicketAction = connect<TicketActionOwnProps, TicketActionRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'

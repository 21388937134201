import {Place, Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {BI_ORIGINS, PlaceWithTicketInfo, saleScheduled} from '@wix/wix-events-commons-statics'
import {Modal} from '../modal'
import {getPlaceInfo, getPlaceQuantity} from '../../../selectors/seating'
import {SEATING_ERROR} from '../../../actions/seating'
import {getTicketById} from '../../../selectors/tickets'
import {Loader} from './loader'
import {ErrorModal} from './error-modal'
import {Mobile} from './mobile'
import {Desktop} from './desktop'
import {classes} from './seating-plan.st.css'
import {LandscapeModal} from './landscape-modal'
import {SeatingPlanProps} from './index'

export const SeatingPlan = ({
  addPlaceToBasket,
  checkout,
  loading,
  seatingError,
  reservationError,
  placesInBasket,
  showAccessibilityMode,
  chooseSeatMode,
  setShowAccessibilityMode,
  selectedPlace,
  unselectPlace,
  selectPlace,
  setSeatingError,
  ticketLimitReached,
  tickets,
  places,
  collapseAreaTickets,
}: SeatingPlanProps) => {
  const [showSeatDescription, setShowSeatDescription] = React.useState(false)
  const [selectedSeatForDescription, setSelectedSeatForDescription] = React.useState<PlaceWithTicketInfo>(null)

  const {t} = useTranslation()
  const {isMobile: mobile} = useEnvironment()
  const desktop = !mobile

  useEffect(() => {
    if (chooseSeatMode) {
      window.parent.postMessage({placesInBasket}, '*')
    }

    document.querySelector('#PAGES_CONTAINER')?.classList.add('ignore-focus')

    return () => document.querySelector('#PAGES_CONTAINER')?.classList.remove('ignore-focus')
  }, [placesInBasket])

  const handleAddClick = useCallback(
    (amount: number = 1) => {
      const currentCount = getPlaceQuantity(placesInBasket, selectedPlace.id)
      const area = selectedPlace.elementType === Type.AREA

      addPlaceToBasket({
        placeId: selectedPlace.id,
        count: area && mobile ? currentCount + amount : amount,
        origin: BI_ORIGINS.SEATINGS_MODAL_BASKET,
      })
    },
    [selectedPlace],
  )

  const handleCheckout = useCallback(() => checkout(), [checkout])

  const onPlaceClick = useCallback(
    (place: Place) => {
      const currentCount = getPlaceQuantity(placesInBasket, place.id)
      const area = place.elementType === Type.AREA
      const ticketLimitReachedError = (ticketLimitReached && area) || (ticketLimitReached && currentCount === 0)

      const handlePlaceSelectUnselect = () =>
        selectedPlace?.id === place.id ? unselectPlace({placeId: place.id}) : selectPlace({placeId: place.id, mobile})

      const handleAddPlaceToBasket = () => {
        const placeWithInfo = getPlaceInfo(places, place.id)
        const ticket = getTicketById(tickets, placeWithInfo.ticketId)

        if (saleScheduled(ticket)) {
          return
        } else {
          setShowSeatDescription(false)
          if (area) {
            mobile
              ? handlePlaceSelectUnselect()
              : addPlaceToBasket({placeId: place.id, count: currentCount + 1, origin: BI_ORIGINS.MAP})
          } else {
            addPlaceToBasket({placeId: place.id, count: currentCount ? 0 : 1, origin: BI_ORIGINS.MAP})
          }
        }
      }

      if (ticketLimitReachedError) {
        setSeatingError(SEATING_ERROR.TICKET_LIMIT_REACHED)
      } else {
        desktop || showAccessibilityMode || currentCount ? handleAddPlaceToBasket() : handlePlaceSelectUnselect()
      }
    },
    [showAccessibilityMode, placesInBasket, selectedPlace],
  )

  const onRemoveClick = useCallback(
    (place: PlaceWithTicketInfo) => {
      const currentCount = getPlaceQuantity(placesInBasket, place.id)
      addPlaceToBasket({
        placeId: place.id,
        count: collapseAreaTickets ? 0 : currentCount - 1,
        origin: BI_ORIGINS.SEATINGS_MODAL_BASKET,
      })
    },
    [placesInBasket],
  )

  const mobileTabs = useMemo(
    () => [{title: t('seatingMapModal_mapTab_title')}, {title: t('seatingMapModal_listTab_title')}],
    [t],
  )

  const onTabClick = useCallback(() => setShowAccessibilityMode(!showAccessibilityMode), [showAccessibilityMode])

  const onCloseClick = useCallback(() => {
    if (selectedPlace?.id) {
      unselectPlace({placeId: selectedPlace.id})
    }
  }, [selectedPlace])

  const selectSeatForDescription = useCallback(
    (seat: PlaceWithTicketInfo) => {
      if (seat) {
        setSelectedSeatForDescription(seat)
        setShowSeatDescription(true)
      } else {
        setShowSeatDescription(false)
      }
    },
    [setSelectedSeatForDescription, setShowSeatDescription],
  )

  return (
    <Modal
      className={classNames({
        [classes.desktopModal]: desktop && !chooseSeatMode,
        [classes.hideCloseButton]: chooseSeatMode,
      })}
      closeButtonAsButton={desktop}
      dataHook={DH.SEATINGS_MODAL}
      title={mobile ? t('seatingMapModal_title') : undefined}
      withoutPadding={desktop}
      tabs={mobile ? mobileTabs : undefined}
      activeTabIndex={showAccessibilityMode ? 1 : 0}
      onTabClick={onTabClick}
    >
      {mobile && <LandscapeModal />}
      <ErrorModal seatingError={seatingError} reservationError={reservationError} />
      {loading ? <Loader /> : null}
      {mobile ? (
        <Mobile
          onRemoveClick={onRemoveClick}
          onCheckoutClick={handleCheckout}
          onPlaceClick={onPlaceClick}
          onCloseClick={onCloseClick}
          onAddClick={handleAddClick}
          selectedSeatForDescription={selectedSeatForDescription}
          showSeatDescription={showSeatDescription}
          selectSeatForDescription={selectSeatForDescription}
        />
      ) : (
        <Desktop
          onPlaceClick={onPlaceClick}
          onRemoveClick={onRemoveClick}
          onCheckoutClick={handleCheckout}
          showAccessibilityMode={showAccessibilityMode}
          selectedSeatForDescription={selectedSeatForDescription}
          showSeatDescription={showSeatDescription}
          selectSeatForDescription={selectSeatForDescription}
        />
      )}
    </Modal>
  )
}
